
























































































































import Vue from 'vue';
import { getEmptyTrainingModule, getTrainingModuleById, getTrainingModuleUsageStats, isPermissionGranted, setTrainingModuleEnabled } from '../repository';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayTrainingModuleSpotElement from '../components/trainingPathModules/DisplayTrainingModuleSpotElement.vue';
import DisplayTrainingModuleMediationElement from '../components/trainingPathModules/DisplayTrainingModuleMediationElement.vue';
import DisplayTrainingModuleUrlElement from '../components/trainingPathModules/DisplayTrainingModuleUrlElement.vue';
import DisplayTrainingModuleLmsElement from '../components/trainingPathModules/DisplayTrainingModuleLmsElement.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { sanitizeHTML } from '../Tools';
import eventBus from '../eventBus';
import { backToList } from '../helpers/filteredListNavigation';

export default Vue.extend({

  name: 'ViewTrainingModule',

  components: {
    DisplayLifetimeEvent,
    DisplayTrainingModuleSpotElement,
    DisplayTrainingModuleMediationElement,
    DisplayTrainingModuleUrlElement,
    DisplayTrainingModuleLmsElement,
    DisplayValue
  },

  data: () => ({
    moduleToView: getEmptyTrainingModule(),
    moduleId: '',
    uses: 0
  }),

  async mounted () {
    if (await isPermissionGranted('perm_TrainingManagement')) {
      this.moduleId = this.$route.params.moduleId;
      await this.initModule();
    } else {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  },

  methods: {
    async initModule (): Promise<void> {
      try {
        const module = await getTrainingModuleById(this.moduleId);
        this.moduleToView = { ...module };
        const moduleStats = await getTrainingModuleUsageStats(this.moduleId);
        this.uses = moduleStats.trainingPath; // number of uses of the module in training paths
      } catch (err) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      }
    },
    getGoals (): string {
      return sanitizeHTML(this.moduleToView.goals).replace(/\n/g, '<br />');
    },
    getDuration (): string {
      // returns something like "x H y Mn", or just "x H" or "y Mn"
      if (this.moduleToView.estimatedDuration === 0) {
        return this.$t('notDefined').toString();
      }
      const h = Math.floor(this.moduleToView.estimatedDuration / 60);
      const m = this.moduleToView.estimatedDuration % 60;
      let value = '';
      if (h > 0) {
        value = h + ' H';
      }
      if (m > 0) {
        value += value === '' ? m + ' Mn' : ' ' + m + ' Mn';
      }
      return value;
    },
    getPassThreshold (): string {
      return this.moduleToView.passThreshold.toString() + ' / 10';
    },
    goBackToList () {
      this.$router.push(backToList('FilteredTrainingModules'));
    },
    async toggleEnabled (): Promise<void> {
      try {
        await setTrainingModuleEnabled(this.moduleId, !this.moduleToView.enabled);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initModule();
      }
    }
  }
});

