



















































































import Vue, { PropType } from 'vue';
import { ApiException, MediationTrainingModuleItemModel, MediationTrainingModuleItemResult } from '../../../_GeneratedClients/SpotClient';
import { getMediationById, queryMediations } from '../../repository';
import { Moment } from 'moment';
import DisplayValue from '../common/DisplayValue.vue';

interface AttachedMediation { id: string; title: string; description: string; mediator: string; status: string; allowed: boolean }

export default Vue.extend({

  name: 'DisplayAssignedModuleMediationElement',

  components: {
    DisplayValue
  },

  props: {
    /** the element to display */
    element: { type: Object as PropType<MediationTrainingModuleItemModel>, required: true },
    /** the result attached to this element (contains attached mediation list) */
    result: { type: Object as PropType<MediationTrainingModuleItemResult>, required: true },
    /** true if parent module is disabled */
    disabled: { type: Boolean, default: false },
    /** the learner of the parent training path */
    learnerId: { type: String, required: true },
    /** the start date of the parent module */
    startDate: { type: String, required: true },
    /** the stop date of the parent module */
    stopDate: { type: String, required: true },
    /** indicates if connected user can manage mediations (attach/detach) ie connected user is referent for the parent module */
    canManageMediation: { type: Boolean, default: false }
  },

  data: () => ({
    /** the list of attached mediations */
    attachedList: [] as AttachedMediation[],
    /** the list of new attachable mediations (that connected user can see) */
    choiceList: [] as AttachedMediation[],
    /** switch to display/hide the mediation selector form */
    showMediationSelector: false,
    loading: true
  }),

  async mounted () {
    await this.initAttachedList();
  },

  methods: {
    async initAttachedList (): Promise<void> {
      this.loading = true;
      // gets mediation id list from result
      this.attachedList = [];
      for (const id of this.result.mediationIds) {
        try {
          // try to load mediation
          const mediation = await getMediationById(id);
          const descriptionLines = mediation.description.split('\n');
          this.attachedList.push({
            id: mediation.id,
            title: mediation.title,
            description: descriptionLines.length > 1 ? descriptionLines[0] + ' ...' : mediation.description,
            mediator: mediation.mediator.name,
            status: mediation.status,
            allowed: true
          });
        } catch (err) {
          if (err instanceof ApiException) {
            if (err.status === 403) {
              // coonected user is not allowed to see this mediation
              this.attachedList.push({ id: id, title: '', description: '', mediator: '', status: '', allowed: false });
            } else {
              // server error is ignored
              console.error(err);
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDisplayableTime (date: Moment): string {
      return date.format(this.$t('dateFormat.timePattern').toString());
    },
    async activateMediationSelection (): Promise<void> {
      // send query to obtain the list of attachable mediations (that connected user can see)
      let query = '(participants oneOf ' + this.learnerId; // learner must be one of participants
      query += '&execution_start beforeOrSameDay ' + this.stopDate; // mediation start date must be same or before module stop date
      query += '&execution_stop afterOrSameDay ' + this.startDate + ')'; // mediation stop date must be same or after module start date
      const list = (await queryMediations({ query: query })).results;
      this.choiceList = [];
      for (const mediation of list) {
        if (this.attachedList.every((m) => m.id !== mediation.id)) {
          // mediation is not yet attached -> can be added to the list of choices
          const descriptionLines = mediation.description.split('\n');
          this.choiceList.push({
            id: mediation.id,
            title: mediation.title,
            description: descriptionLines.length > 1 ? descriptionLines[0] + ' ...' : mediation.description,
            mediator: mediation.mediator.name,
            status: mediation.status,
            allowed: true
          });
        }
      }
      this.showMediationSelector = true;
    },
    onMediationClicked (mediationId: string): void {
      this.$emit('attach', mediationId);
      this.showMediationSelector = false;
    },
    removeMediation (mediationId: string): void {
      this.$emit('detach', mediationId);
    }
  },

  watch: {
    result: async function (): Promise<void> {
      await this.initAttachedList();
    }
  }
});

