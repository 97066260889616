















































import Vue, { PropType } from 'vue';
import { UrlTrainingModuleItemModel, UrlTrainingModuleItemResult } from '../../../_GeneratedClients/SpotClient';
import { Moment } from 'moment';

export default Vue.extend({

  name: 'DisplayAssignedModuleUrlElement',

  props: {
    /** the module url element to be displayed */
    element: { type: Object as PropType<UrlTrainingModuleItemModel>, required: true },
    /** the actual result of the element */
    result: { type: Object as PropType<UrlTrainingModuleItemResult>, required: true },
    /** true if parent module is disabled */
    disabled: { type: Boolean, default: false },
    /** true if action button click must have a result */
    isActive: { type: Boolean, default: false }
  },

  methods: {
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDisplayableTime (date: Moment): string {
      return date.format(this.$t('dateFormat.timePattern').toString());
    },
    onUrlClicked (): void {
      if (!this.disabled && this.isActive && !this.done) {
        this.$emit('urlClicked');
      }
    }
  },

  computed: {
    done: function (): boolean {
      return this.result.doneAt !== undefined;
    }
  }
});

