































import Vue from 'vue';
import { ApiException, IdentityProviderModel } from '../../_GeneratedClients/SpotClient';
import { login, loginFromCookie, getIdentityProviders } from '../repository';
import logUser from '../components/headerBar/logUser.vue';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'Login',

  components: {
    logUser
  },

  data: () => ({
    connectError: '',
    waitOnTry: false,
    identityProviders: [] as IdentityProviderModel[],
    redirectUrl: '/spotv2/'
  }),

  async created () {
    let redirect = this.$route.query.returnUrl;
    if (typeof (redirect) !== 'string' || redirect === '') {
      redirect = '/spotv2/';
    }

    const otherQueryParams = { ...this.$route.query } as Record<string, string>;
    delete otherQueryParams.returnUrl;

    const searchParams = new URLSearchParams(otherQueryParams).toString();
    if (searchParams.length > 0) {
      redirect += '?' + searchParams;
    }

    this.redirectUrl = redirect;

    // Test if we are already connected with the cookie, just return to the requested URL
    if (await loginFromCookie()) {
      this.redirectToReturnUrl();
    }

    this.identityProviders = await getIdentityProviders(redirect);
  },

  methods: {
    async onConnectClicked (credentials: { username: string, password: string }) {
      this.connectError = '';
      this.waitOnTry = true;
      if (credentials.username.length === 0 || credentials.password.length === 0) {
        this.connectError = this.$t('loginError').toString();
        this.waitOnTry = false;
      } else {
        try {
          this.redirectUrl = await login({ ...credentials, returnUrl: this.redirectUrl });
          this.redirectToReturnUrl();
        } catch (err) {
          if ((err as ApiException).status === 401) {
            this.connectError = this.$t('loginError').toString();
          } else {
            eventBus.$emit('error', err, null);
          }
          this.waitOnTry = false;
        }
      }
    },
    redirectToReturnUrl () {
      if (this.redirectUrl.startsWith('/spotv2')) {
        this.$router.push(this.redirectUrl.substring('/spotv2'.length));
        return;
      }

      window.location.href = this.redirectUrl;
    }
  }
});
