



















































































































import Vue from 'vue';
import { registerLoginEvent, isPermissionGranted, resolvePermissionName } from '../../repository';
import SearchBar from './SearchBar.vue';
import { UserModel } from '../../../_GeneratedClients/SpotClient';
import AddItemMenu from './AddItemMenu.vue';
import TeamworkMenu from './TeamworkMenu.vue';
import { LocaleMessage } from 'vue-i18n';

interface LinkMenuItem { title: LocaleMessage; icon: string; link: string }
interface GroupMenuItem { title: LocaleMessage; icon: string; link: null; children: { title: LocaleMessage; link: string }[] }
type MenuItem = LinkMenuItem | GroupMenuItem;

export default Vue.extend({

  name: 'HeaderBar',

  components: {
    AddItemMenu,
    SearchBar,
    TeamworkMenu
  },

  data: () => ({
    drawer: false,
    counter: 0,
    connectedUserName: '',
    bellAlert: false, // show (true) / hide (false) notification icon (bell)
    allowCollaboration: true, // show/hide collaboration button
    enableAddButton: true, // show/hide "plus button" to add subjects and other
    newSubjects: true,
    canManageTraining: false,
    canSeeTrainingPathDashboard: false,
    canAdminTrainingReferences: false,
    canSetPermissions: false,
    canManageQualifications: false,
    showGroupsAndPermissions: false,
    myGroupList: [] as string[],
    myPermissionList: [] as string[],
    loggedUser: null as UserModel | null
  }),

  created () {
    registerLoginEvent(this.onLoginChanged);
  },

  methods: {
    async onLoginChanged (loggedUser: UserModel | null) {
      this.loggedUser = loggedUser;
      const authenticated = (loggedUser !== null);
      this.connectedUserName = authenticated ? loggedUser.name : '';
      this.canManageTraining = authenticated && await isPermissionGranted('perm_TrainingManagement');
      this.canSeeTrainingPathDashboard = authenticated && await isPermissionGranted('perm_TableauBord');
      this.canAdminTrainingReferences = authenticated && await isPermissionGranted('perm_TrainingReferenceAdmin');
      this.canSetPermissions = authenticated && await isPermissionGranted('perm_SetPermissions');
      this.canManageQualifications = authenticated && await isPermissionGranted('perm_QualificationAdmin');
      this.myGroupList = authenticated ? loggedUser.groups.map((g) => g.name) : [];
      this.myPermissionList = authenticated ? loggedUser.permissions : [];
    },
    getPermissionLabel (permission: string): string {
      return resolvePermissionName(permission);
    }
  },

  computed: {
    menuItems (): MenuItem[] {
      const items = [] as MenuItem[];
      items.push({ title: this.$t('lms'), icon: 'mdi-school-outline', link: '/lms' });
      items.push({ title: this.$t('cartographies'), icon: 'mdi-sitemap', link: '/cartographies?applyDefaultFilter=true' });
      items.push({ title: this.$t('functions'), icon: 'mdi-dns-outline', link: '/functions?applyDefaultFilter=true' });
      items.push({ title: this.$t('subjects'), icon: 'mdi-text-box-outline', link: '/subjects?applyDefaultFilter=true' });
      items.push({ title: this.$t('mediations'), icon: 'mdi-account-multiple', link: '/mediations?applyDefaultFilter=true' });
      if (this.canManageTraining) {
        items.push({ title: this.$t('modules'), icon: 'mdi-view-module', link: '/training/modules?applyDefaultFilter=true' });
      }
      items.push({ title: this.$t('trainingPaths'), icon: 'mdi-book-open-page-variant-outline', link: '/training/paths?applyDefaultFilter=true' });
      items.push({ title: this.$t('qualifications'), icon: 'mdi-certificate-outline', link: '/qualifications' });
      items.push({ title: this.$t('myDashboard'), icon: 'mdi-chart-areaspline', link: '/dashboards/mine' });
      if (this.canSeeTrainingPathDashboard) {
        items.push({
          title: this.$t('dashboardManager'),
          icon: 'mdi-counter',
          link: null,
          children: [
            { title: this.$t('trainingPathsProgress'), link: '/dashboards/trainingPath' },
            { title: this.$t('contributions'), link: '/dashboards/contributions' },
            { title: this.$t('trainingPathReports'), link: '/administration/trainingPathReport' }
          ]
        });
      }

      const adminItem: MenuItem = {
        title: this.$t('admin'),
        icon: 'mdi-cog-outline',
        link: null,
        children: []
      };
      if (this.canAdminTrainingReferences) {
        adminItem.children.push(
          { title: this.$t('trainingModuleReferences'), link: '/administration/trainingModuleReferences?applyDefaultFilter=true' },
          { title: this.$t('trainingPathReferences'), link: '/administration/trainingPathReferences?applyDefaultFilter=true' }
        );
      }
      if (this.canSetPermissions) {
        adminItem.children.push({ title: this.$t('permissionsManagement'), link: '/administration/permissions' });
        adminItem.children.push({ title: this.$t('cartographiesPermissions'), link: '/administration/cartographies/permissions' });
      }

      if (adminItem.children.length > 0) {
        items.push(adminItem);
      }
      return items;
    },
    userDisplayName (): string {
      return this.authenticated ? this.connectedUserName : this.$t('notAuthenticated').toString();
    },
    authenticated (): boolean {
      return this.connectedUserName !== '';
    }
  }
});

