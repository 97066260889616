









































































































































































import Vue, { PropType } from 'vue';
import { IdAndNameModel, SavedFilter } from '../../../_GeneratedClients/SpotClient';
import {
  FieldType,
  BooleanComparisonOperator,
  DateComparisonOperator,
  QueryCriterion,
  EnumComparisonOperator,
  StringComparisonOperator,
  BooleanQueryCriterion,
  DateQueryCriterion,
  EnumQueryCriterion,
  IdQueryCriterion,
  MultipleQueryCriteria,
  MultipleQueryCriteriaType,
  SingleQueryCriterion,
  StringQueryCriterion,
  FrontendEnumFieldConfiguration,
  FrontendFieldConfiguration,
  FrontendIdFieldConfiguration,
  FrontendLocationFieldConfiguration,
  FrontendCriterion,
  CriterionValueType,
  serializeQuery,
  SortingCriterion,
  serializeSorting
} from '../../queryEngine';
import QueryCriterionSelector from './QueryCriterionSelector.vue';
import { getFilterList, updateFilterList } from '../../repository';
import { LocalDate } from '@js-joda/core';
import { truncate } from '../../Tools';

interface SortableField { id: string; name: string; type: FieldType; }
export default Vue.extend({

  name: 'FilterBar',

  components: {
    QueryCriterionSelector
  },

  props: {
    /** define list and configuration of possibles criteria to build a filter */
    fieldsConfiguration: { type: Object as PropType<Record<string, FrontendFieldConfiguration>>, required: true },
    filter: { type: Object as PropType<QueryCriterion>, required: true },
    sorting: { type: Array as PropType<SortingCriterion[]>, required: true },
    /** type of object using the filter (type of parent list objects) - used to manage registered filters */
    entityType: { type: String, default: '' }
  },

  data: () => ({
    openedFilterPanel: null as number | null,
    searchCriteria: [] as FrontendCriterion[],
    sortingCriterion: null as SortingCriterion | null,
    criteriaError: '',
    tooComplex: false, // Indicates that the given query is too complex to be displayed by the selector
    showSaveFilterPanel: false,
    filterList: [] as SavedFilter[],
    currentFilterName: '',
    filterNameError: '',
    registeredFiltersLoaded: false,
    sortableFields: [] as SortableField[],
    selectedSortingField: null as SortableField | null,
    ascendingSortOrder: true,
    currentSortingCriterion: ''
  }),

  async created () {
    // get registered filters for defined entity type
    this.filterList = await getFilterList(this.entityType);
    this.registeredFiltersLoaded = true;
  },

  methods: {
    getBgColor (error: string): string {
      return error === '' ? 'bgAccent' : 'bgError';
    },
    bookmarkColor (idx: number): string {
      return this.filterList[idx].isDefault === true ? 'accent--text' : 'secondary--text';
    },
    bookmarkIcon (idx: number): string {
      return this.filterList[idx].isDefault === true ? 'mdi-bookmark-check' : 'mdi-bookmark-outline';
    },
    addFilterCriterion () {
      this.criteriaError = '';
      this.searchCriteria.push({
        field: undefined,
        operator: '',
        value: '',
        error: true
      });
    },
    async addQueryCriterion (criterion: SingleQueryCriterion) : Promise<void> {
      switch (criterion.type) {
        case FieldType.string: this.searchCriteria.push({
          field: criterion.property,
          operator: criterion.operator,
          value: criterion.value,
          error: false
        }); break;
        case FieldType.boolean: this.searchCriteria.push({
          field: criterion.property,
          operator: criterion.operator,
          value: criterion.value,
          error: false
        }); break;
        case FieldType.date:
          this.searchCriteria.push({
            field: criterion.property,
            operator: criterion.operator,
            value: criterion.value,
            error: false
          });
          break;
        case FieldType.enum:
          {
            const fieldConfig = this.fieldsConfiguration[criterion.property] as FrontendEnumFieldConfiguration;
            const resolvedValues: (IdAndNameModel | undefined)[] = criterion.values.map(v => fieldConfig.frontendEnumValues.find(e => e.id === v));
            this.searchCriteria.push({
              field: criterion.property,
              operator: criterion.operator,
              value: resolvedValues.filter(v => v !== undefined) as IdAndNameModel[],
              error: resolvedValues.some(v => v === undefined)
            });
          }
          break;
        case FieldType.id:
          {
            const fieldConfig = this.fieldsConfiguration[criterion.property] as FrontendIdFieldConfiguration | FrontendLocationFieldConfiguration;
            const resolvedNames: Record<string, string> = await fieldConfig.resolveNames(criterion.values);
            const resolvedValues: (IdAndNameModel | undefined)[] = criterion.values.map(v => {
              if (resolvedNames[v] === undefined) {
                return undefined;
              } else {
                return {
                  id: v,
                  name: resolvedNames[v]
                };
              }
            });
            this.searchCriteria.push({
              field: criterion.property,
              operator: criterion.operator,
              value: resolvedValues.filter(v => v !== undefined) as IdAndNameModel[],
              error: resolvedValues.some(v => v === undefined)
            });
          }
          break;
        default: throw new Error('Unsupported field type');
      }
    },
    deleteCriterion (idx: number) {
      this.criteriaError = '';
      this.searchCriteria.splice(idx, 1);
    },
    fieldSelected (idx: number, selectedField: string) {
      // criterion type selection changed
      this.criteriaError = '';
      this.searchCriteria[idx].field = selectedField; // new field
      this.searchCriteria[idx].operator = ''; // reset operator selection
      const fieldConfiguration = this.fieldsConfiguration[selectedField];
      let defaultValue: CriterionValueType;
      let error = true;
      switch (fieldConfiguration.type) {
        case FieldType.string:
          defaultValue = '';
          error = false;
          break;
        case FieldType.boolean:
          defaultValue = true;
          error = false;
          break;
        case FieldType.date:
          defaultValue = LocalDate.now();
          break;
        case FieldType.id:
        case FieldType.enum:
          defaultValue = [] as IdAndNameModel[];
          break;
      }
      this.searchCriteria[idx].value = defaultValue;
      this.searchCriteria[idx].error = error; // reset error
    },
    operatorSelected (idx: number, operator: string) {
      // criterion operator changed
      this.criteriaError = '';
      this.searchCriteria[idx].operator = operator;
    },
    valueSelected (idx: number, value: CriterionValueType) {
      // criterion value changed
      this.criteriaError = '';
      this.searchCriteria[idx].value = value;
    },
    toggleSortOrder (): void {
      this.ascendingSortOrder = !this.ascendingSortOrder;
      this.sortCriterionSelected();
    },
    sortCriterionSelected (): void {
      this.sortingCriterion = this.selectedSortingField === null
        ? null
        : {
            property: this.selectedSortingField.id,
            ascending: this.ascendingSortOrder
          };
    },
    errorChanged (idx: number, error: boolean) {
      // criterion error changed
      this.criteriaError = '';
      this.searchCriteria[idx].error = error;
    },
    validateCriteria (): boolean {
      if (this.searchCriteria.some((sc) => sc.error)) {
        this.criteriaError = this.$t('atLeastOneErrorLeft').toString();
        return false;
      }
      this.criteriaError = '';
      return true;
    },
    applyCriteria () {
      if (this.validateCriteria()) {
        this.openedFilterPanel = null; // closes filter panel
        this.emitFilter(serializeQuery(this.buildQuery()), this.sortingCriterion ? serializeSorting([this.sortingCriterion]) : '');
        this.currentSortingCriterion = this.selectedSortingField ? this.selectedSortingField.name : '';
      }
    },
    saveCriteriaAsked (): void {
      if (this.validateCriteria()) {
        this.showSaveFilterPanel = true;
        this.filterNameValidator();
      }
    },
    async onSaveFilterClicked (): Promise<void> {
      if (this.filterNameError !== '') {
        return;
      }
      this.showSaveFilterPanel = false;
      const existingFilter = this.filterList.find((f) => f.name === this.currentFilterName);
      if (existingFilter) {
        // TODO : ask before overwriting an existing filter name (???)
        existingFilter.filter = serializeQuery(this.buildQuery());
        existingFilter.sorting = this.sortingCriterion ? serializeSorting([this.sortingCriterion]) : '';
      } else {
        this.filterList.push({ name: this.currentFilterName, filter: serializeQuery(this.buildQuery()), sorting: this.sortingCriterion ? serializeSorting([this.sortingCriterion]) : '', isDefault: false });
      }
      await updateFilterList(this.entityType, this.filterList);
      this.currentFilterName = '';
    },
    filterNameValidator (): void {
      this.filterNameError = '';
      if (this.currentFilterName !== this.currentFilterName.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.filterNameError = this.$t('beginEndWhitespaceError').toString();
      } else if (this.currentFilterName.length === 0) {
        // name must be set
        this.filterNameError = this.$t('emptyFieldError').toString();
      }
    },
    filterActivationClicked (filter: SavedFilter): void {
      this.emitFilter(filter.filter, filter.sorting ?? '');
    },
    async deleteFilterClicked (filter: SavedFilter): Promise<void> {
      this.filterList = this.filterList.filter((f) => f.name !== filter.name);
      await updateFilterList(this.entityType, this.filterList);
    },
    async setFilterAsDefault (idx: number): Promise<void> {
      if (this.filterList[idx].isDefault === true) {
        // unselect default value
        this.filterList[idx].isDefault = false;
      } else {
        // unselect old value (if exists)
        const filter = this.filterList.find((f) => f.isDefault === true);
        if (filter) {
          filter.isDefault = false;
        }
        // select new value
        this.filterList[idx].isDefault = true;
      }
      await updateFilterList(this.entityType, this.filterList);
    },
    resetFilter () {
      this.emitFilter('', '');
      this.searchCriteria = [];
      this.currentFilterName = '';
    },
    buildQuery () : QueryCriterion {
      const subQuery = [] as QueryCriterion[];
      for (const sc of this.searchCriteria as FrontendCriterion[]) {
        if (sc.field === undefined) {
          throw new Error('field was undefined, this is a bug.');
        }
        const config = this.fieldsConfiguration[sc.field];
        switch (config.type) {
          case FieldType.string:
            subQuery.push({
              type: config.type,
              property: sc.field,
              operator: sc.operator as StringComparisonOperator,
              value: sc.value
            } as StringQueryCriterion);
            break;
          case FieldType.boolean:
            subQuery.push({
              type: config.type,
              property: sc.field,
              operator: sc.operator as BooleanComparisonOperator,
              value: sc.value as boolean
            } as BooleanQueryCriterion);
            break;
          case FieldType.date:
            subQuery.push({
              type: config.type,
              property: sc.field,
              operator: sc.operator as DateComparisonOperator,
              value: sc.value as LocalDate
            } as DateQueryCriterion);
            break;
          case FieldType.enum:
            subQuery.push({
              type: config.type,
              property: sc.field,
              operator: sc.operator as EnumComparisonOperator,
              values: (sc.value as IdAndNameModel[]).map((v) => v.id)
            } as EnumQueryCriterion);
            break;
          case FieldType.id:
            subQuery.push({
              type: config.type,
              property: sc.field,
              operator: sc.operator as EnumComparisonOperator,
              values: (sc.value as IdAndNameModel[]).map((v) => v.id)
            } as IdQueryCriterion);
            break;
        }
      }
      return { type: MultipleQueryCriteriaType.and, criteria: subQuery } as MultipleQueryCriteria;
    },
    emitFilter (query: string, sorting: string) {
      this.$emit('filterChanged', query, sorting);
    },
    truncated (text: string): string {
      return truncate(text, 25);
    }
  },

  computed: {
    emptyFilter: function (): boolean {
      if (this.filter) {
        if ('criteria' in this.filter) {
          // this is a MultipleQueryCriteria
          return (this.filter as MultipleQueryCriteria).criteria.length === 0;
        }
        // this is a SingleQueryCriterion
        return false;
      }
      // there is no filter
      return true;
    },
    sortingIcon (): string {
      if (!this.selectedSortingField) {
        return '';
      }
      let iconName = 'mdi-sort-';
      if (this.selectedSortingField.type === FieldType.enum) {
        iconName += 'bool-';
        iconName += this.ascendingSortOrder === true ? 'ascending' : 'descending';
        return iconName + '-variant';
      }
      switch (this.selectedSortingField.type) {
        case FieldType.boolean: iconName += 'bool-'; break;
        case FieldType.string: iconName += 'alphabetical-'; break;
        case FieldType.date: iconName += 'calendar-'; break;
      }
      iconName += this.ascendingSortOrder === true ? 'ascending' : 'descending';
      return iconName;
    },
    registeredFilterName (): string {
      const filterString = this.$route.params.filter;
      if (filterString === undefined) {
        return '';
      }
      const foundFilter = this.filterList.find((f) => f.filter === filterString);
      return foundFilter === undefined ? '' : '(' + foundFilter.name + ')';
    }
  },

  watch: {
    fieldsConfiguration: {
      immediate: true,
      handler: function (): void {
        for (const fieldName in this.fieldsConfiguration) {
          if (this.fieldsConfiguration[fieldName].sortable === true) {
            this.sortableFields.push({
              id: fieldName,
              name: this.fieldsConfiguration[fieldName].displayName,
              type: this.fieldsConfiguration[fieldName].type
            });
          }
        }
      }
    },
    filter: {
      immediate: true,
      handler: async function (): Promise<void> {
        this.searchCriteria = [];
        this.criteriaError = '';
        const currentFilter = this.filter as QueryCriterion;// Fix typescript not able to detect type
        let parseSucceeded = false;
        if (Object.values(MultipleQueryCriteriaType).includes(currentFilter.type as MultipleQueryCriteriaType) && (currentFilter as MultipleQueryCriteria).criteria.length === 0) {
          parseSucceeded = true;
        }
        const fieldTypes = Object.keys(FieldType);
        if (currentFilter.type === MultipleQueryCriteriaType.and && currentFilter.criteria.every(c => fieldTypes.includes(c.type))) {
          // If it is an & query with simple field types
          for (const subFilter of currentFilter.criteria as SingleQueryCriterion[]) {
            await this.addQueryCriterion(subFilter);
          }
          parseSucceeded = true;
        } else if (fieldTypes.includes(currentFilter.type)) {
          await this.addQueryCriterion(currentFilter as SingleQueryCriterion);
          parseSucceeded = true;
        }
        if (!parseSucceeded) {
          this.tooComplex = true;
        }
      }
    },
    sorting: {
      immediate: true,
      handler: function (): void {
        this.sortingCriterion = this.sorting[0] ?? null; // TODO: support multiple sorting criteria
        if (this.sortingCriterion !== null) {
          this.ascendingSortOrder = this.sortingCriterion.ascending ?? true;
          this.selectedSortingField = this.sortableFields.find((sf) => sf.id === this.sortingCriterion?.property as string) ?? null;
          this.currentSortingCriterion = this.selectedSortingField ? this.selectedSortingField.name : '';
        }
      }
    }
  }
});

