import {
  AssignedTrainingModuleModel,
  AssignedTrainingModuleQueryResultModel,
  CreateTrainingPathRequest,
  EntityCreatedModel,
  IdAndNameModel,
  LifetimeEvent,
  LmsCourseModelNode,
  QueryRequest,
  // eslint-disable-next-line camelcase
  QueryResponse_TrainingPathModel_,
  SetGradeRequest,
  SignatureRequest,
  TrainingPathInfoModel,
  TrainingPathModel,
  TrainingPathStatus,
  TrainingPathValidationRequest,
  UpdateTrainingPathRequest
} from '../../_GeneratedClients/SpotClient';
import moment from 'moment';
import { client } from './Client';

/**
 * creates an empty training path
 *
 * @returns an empty training path object
 */
export function getEmptyTrainingPath (): TrainingPathModel {
  return {
    id: '',
    learner: { id: '', name: '' } as IdAndNameModel,
    visibleByGroups: [] as IdAndNameModel[],
    visibleByPersons: [] as IdAndNameModel[],
    status: TrainingPathStatus.NotAccepted,
    creation: { userId: '', userName: '', date: moment() } as LifetimeEvent,
    title: '',
    reference: undefined, // Avoids reactivity issues. the property exists but is not defined
    startDate: moment().startOf('day'),
    stopDate: moment().endOf('day'),
    passThreshold: 10,
    assignedModules: [] as AssignedTrainingModuleModel[],
    doneSubModuleItems: 0,
    totalSubModuleItems: 0,
    totalEstimatedDuration: 0,
    totalAssignedModules: 0
  };
}

/**
 * Transforms path datas from API format to front format
 * - transforms dates from iso string to moment
 * - add reference as undefined if necessary
 *
 * @param path the object where to transform dates
 * @returns path object with dates as moment instead of iso strings
 */
function transformApiPath (path: TrainingPathModel): TrainingPathModel {
  path.startDate = moment(path.startDate);
  path.stopDate = moment(path.stopDate);
  if (!('reference' in path)) {
    path.reference = undefined; // Avoids reactivity issues. the property exists but is not defined
  }
  if (path.acceptanceDate !== undefined) {
    path.acceptanceDate = moment(path.acceptanceDate);
  }
  if (path.validation !== undefined) {
    path.validation.date = moment(path.validation.date);
  }
  if (path.signature !== undefined) {
    path.signature.date = moment(path.signature.date);
  }
  for (const module of path.assignedModules) {
    module.startDate = moment(module.startDate);
    module.stopDate = moment(module.stopDate);
    for (const result of module.results) {
      if (result.doneAt) {
        result.doneAt = moment(result.doneAt);
      }
    }
    if (module.grading !== undefined) {
      module.grading.date = moment(module.grading.date);
    }
    if (module.signature !== undefined) {
      module.signature.date = moment(module.signature.date);
    }
  }
  return path;
}

/**
 * gets training path list from api, with query filter(s)
 *
 * @param query query parameters (filters)
 * @returns a promise, the list of training paths according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryPaths (query: QueryRequest): Promise<QueryResponse_TrainingPathModel_> {
  const paths = await client.queryTrainingPaths(query);
  return {
    results: paths.results.map((p) => transformApiPath(p)),
    totalItems: paths.totalItems
  };
}

/**
 * Gets a training path object from API
 *
 * @param pathId the identifier of the training path to obtain
 * @returns a promise, the training path object asked
 */
export async function getTrainingPathById (pathId: string): Promise<TrainingPathModel> {
  const path = await client.getTrainingPathById(pathId);
  return transformApiPath(path);
}

/**
 *Asks API to save a new training path into database
 *
 * @param toSave elements to save
 * @returns a promise, a result containing the identifier of the created object
 */
export async function createTrainingPaths (toSave: CreateTrainingPathRequest): Promise<EntityCreatedModel[]> {
  return await client.createTrainingPaths(toSave);
}

/**
 *Asks API to update a registered training path
 *
 * @param pathId the identifier of the training path to update
 * @param toUpdate elements to save
 * @returns a promise, nothing
 */
export async function updateTrainingPath (pathId: string, toUpdate: UpdateTrainingPathRequest): Promise<void> {
  return await client.updateTrainingPath(pathId, toUpdate);
}

/**
 * Asks API to delete a training path
 *
 * @param pathId the identifier of the training path to remove
 * @returns nothing
 */
export async function deleteTrainingPath (pathId: string): Promise<void> {
  return await client.deleteTrainingPath(pathId);
}

/**
 *
 * @param pathId Asks API to change status of a training path from "notAccepted", to "accepted" or "started" (depending of start date)
 */
export async function startTrainingPath (pathId: string): Promise<void> {
  await client.startTrainingPath(pathId);
}

/**
 * says to API that an item in a module in a training path was clicked
 *
 * @param pathId the identifier of the training path
 * @param moduleIdx the index of the module in modules array from the path
 * @param elementIdx the index of the item in items array from the module
 */
export async function setTrainingPathUrlResult (pathId: string, moduleIdx: number, elementIdx: number): Promise<void> {
  await client.setTrainingPathUrlResult(pathId, moduleIdx, elementIdx);
}

/**
 * asks API to update training path results from tracking
 *
 * @param pathId the identfier of the path to update
 * @returns nothing
 */
export async function updateTrainingPathResults (pathId: string): Promise<void> {
  return client.updateTrainingPathResults(pathId);
}

/**
 * ask API to register the grade for an assigned module in a training path
 *
 * @param pathId the identifier of the training path containing the module to grade
 * @param moduleIdx the index of the module to grade in training path assigned modules array
 * @param rating the grade and comment to register
 */
export async function setAssignedModuleGrade (pathId: string, moduleIdx: number, rating: SetGradeRequest): Promise<void> {
  await client.setAssignedModuleGrade(pathId, moduleIdx, rating);
}

/**
 * ask API to register learner signature for an asigned module in a training path
 *
 * @param pathId the identifier of the training path containing the module to sign
 * @param moduleIdx the index of the module to sign in training path assigned modules array
 * @param comment the learner comment to register
 */
export async function signAssignedModule (pathId: string, moduleIdx: number, comment: SignatureRequest): Promise<void> {
  await client.signAssignedModule(pathId, moduleIdx, comment);
}

/**
 * Ask API to register the manager validation for a training path
 *
 * @param pathId the identifier of the training path to validate
 * @param comment the manager comment to register
 */
export async function validateTrainingPath (pathId: string, comment: TrainingPathValidationRequest): Promise<void> {
  await client.validateTrainingPath(pathId, comment);
}

/**
 * Ask API to register the manager validation for a training path
 *
 * @param pathId the identifier of the training paty to validate
 * @param comment the manager comment to register
 */
export async function signTrainingPath (pathId: string, comment: SignatureRequest): Promise<void> {
  await client.signTrainingPath(pathId, comment);
}

/**
 * Ask API to attach a mediation to an assigned module mediation element
 *
 * @param pathId the identifier of the training path to update
 * @param moduleIdx the index of the module to update in training path assigned modules array
 * @param elementIdx the index of the element (assigned module mediation element) in module element list
 * @param mediationId the identifier of the mediation to attach
 */
export async function attachMediationToTrainingPathResult (pathId: string, moduleIdx: number, elementIdx: number, mediationId: string): Promise<void> {
  await client.attachMediationToTrainingPathResult(pathId, moduleIdx, elementIdx, mediationId);
}
/**
 * Ask API to detach a mediation to an assigned module mediation element
 *
 * @param pathId the identifier of the training path to update
 * @param moduleIdx the index of the module to update in training path assigned modules array
 * @param elementIdx the index of the element (assigned module mediation element) in module element list
 * @param mediationId the identifier of the mediation to detach
 */
export async function detachMediationFromTrainingPathResult (pathId: string, moduleIdx: number, elementIdx: number, mediationId: string): Promise<void> {
  await client.detachMediationFromTrainingPathResult(pathId, moduleIdx, elementIdx, mediationId);
}

/**
 * gets the tree list of LMS courses from API
 *
 * @returns the tree list of courses
 */
export async function getAllCourses (): Promise<LmsCourseModelNode[]> {
  return await client.getAllCourses();
}

/**
 * gets a partial tree list (or an leaf item) of LMS courses from API
 *
 * @param id the identifier of the starting node
 * @returns a tree or a leaf
 */
export async function getCourseById (id: string): Promise<LmsCourseModelNode> {
  return await client.getCourseById(id);
}

/**
 * Gets the link of attempt best graded for a quiz on lms system
 *
 * @param quizAttemptIds the attempt identifiers for all attempts the learner made for this quiz
 * @returns the link to the attempt result page
 */
export async function getLmsAttemptLink (quizAttemptIds: string[]): Promise<string> {
  const attempts = await client.getLmsQuizAttempts(quizAttemptIds);
  if (attempts.length === 0) {
    return '';
  }
  // get attempt with best grade
  const bestAttempt = attempts.reduce((best, current) => current.grade > best.grade ? current : best);
  // returns link
  return bestAttempt.link;
}

/**
 * asks API to change a module starting date to now (current day)
 *
 * @param pathId the identifier of the training path containing the module to update
 * @param moduleIndex the index of the module to change in training path assigned modules array
 */
export async function startTrainingPathModuleNow (pathId: string, moduleIndex: number): Promise<void> {
  await client.startTrainingPathModuleNow(pathId, moduleIndex);
}

/**
 * asks API to disable a module in a training path
 *
 * @param pathId the identifier of the training path containing the module to disable
 * @param moduleIndex the index of the module to disable in training path assigned modules array
 */
export async function disableTrainingPathModule (pathId: string, moduleIndex: number): Promise<void> {
  await client.disableTrainingPathModule(pathId, moduleIndex);
}

/**
 * asks API to tag a training path as canceled
 *
 * @param trainingPathId the identifier of the training path to tag as canceled
 */
export async function cancelTrainingPath (trainingPathId: string): Promise<void> {
  await client.cancelTrainingPath(trainingPathId);
}

/**
 * gets assigned training module list from api
 *
 * @param query query parameters
 * @returns a promise, the list of assigned training modules according to query parameters
 */
export async function queryAssignedTrainingModules (query: QueryRequest): Promise<AssignedTrainingModuleQueryResultModel[]> {
  const result = (await client.queryAssignedTrainingModules(query)).results;
  // dates are seen as Moment, but are actually strings. Must convert to real Moment objects (if not, moment functions don't work)
  for (const item of result) {
    item.assignedModule.startDate = moment(item.assignedModule.startDate);
    item.assignedModule.stopDate = moment(item.assignedModule.stopDate);
  }
  return result;
}

/**
 * gets a list of training paths from API
 *
 * @param trainingPathIds the list of training path identifiers to get
 * @returns a list of training paths
 */
export async function getTrainingPathInfoByIds (trainingPathIds: string[]): Promise<{ [key: string]: TrainingPathInfoModel }> {
  const pathList = await client.getTrainingPathInfoByIds(trainingPathIds);
  // dates are seen as Moment, but are actually strings. Must convert to real Moment objects (if not, moment functions don't work)
  for (const item of Object.values(pathList)) {
    item.startDate = moment(item.startDate);
    item.stopDate = moment(item.stopDate);
  }
  return pathList;
}

export interface TrainingPathCountingEntity { id: string; name: string; progress: number[]; average: number; state: keyof TrainingPathProgressLegend; }

export interface TrainingPathGroupEntity { group: TrainingPathCountingEntity; users: TrainingPathCountingEntity[] }

export interface TrainingPathProgressLegend { low: boolean; medium: boolean; high: boolean; }

/**
 * Gets initialized training path progress legend object
 *
 * @returns the initialized object
 */
export function getInitialTrainingPathProgressLegend (): TrainingPathProgressLegend {
  return { low: true, medium: true, high: true };
}

/**
 * gets background color name for training path progress legend and tiles according their state
 *
 * @param state the current state of object
 * @returns background color name.
 */
export function getTrainingPathProgressLegendColor (state: string): string {
  switch (state) {
    case 'low': return 'bgError';
    case 'medium': return 'bgAccent';
    case 'high': return 'bgSuccess';
    default: return 'white';
  }
}

/**
 * gets state corresponding to progress
 *
 * @param progress the progress value (0 - 100)
 * @returns the corresponding state
 */
export function getTrainingPathProgressState (progress: number): keyof TrainingPathProgressLegend {
  if (progress < 33) {
    return 'low';
  }
  if (progress > 66) {
    return 'high';
  }
  return 'medium';
}
