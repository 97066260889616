

































































import Vue, { PropType } from 'vue';
import { Moment } from 'moment';
import {
  ApiException,
  LmsCourseModelLeafNode,
  LmsCourseModelNode,
  LmsCourseModelNodeType,
  LmsTrainingModuleItemModel,
  LmsTrainingModuleItemResult
} from '../../../_GeneratedClients/SpotClient';
import { getCourseById, getLmsAttemptLink } from '../../repository';
import eventBus from '../../eventBus';

interface LmsCourseModelLeafNodeViewModel extends LmsCourseModelLeafNode { grade: string; attemptLink: string; }

export default Vue.extend({

  name: 'DisplayAssignedModuleLmsElement',

  props: {
    /** the element to display */
    element: { type: Object as PropType<LmsTrainingModuleItemModel>, required: true },
    /** the result attached to this element */
    result: { type: Object as PropType<LmsTrainingModuleItemResult>, required: true },
    /** true if parent module is disabled */
    disabled: { type: Boolean, default: false }
  },

  data: () => ({
    itemList: [] as LmsCourseModelLeafNodeViewModel[],
    promises: [] as Promise<LmsCourseModelLeafNodeViewModel>[],
    root: undefined as LmsCourseModelNode | undefined,
    lmsElementNotFound: false
  }),

  async mounted () {
    await this.init();
  },

  methods: {
    async init (): Promise<void> {
      try {
        this.lmsElementNotFound = false;
        this.root = await getCourseById(this.element.lmsCourseId);
        // get sub-element list
        await this.addLeafs(this.root);
        this.itemList = await Promise.all(this.promises);
      } catch (err) {
        if (err instanceof ApiException && err.status === 404) {
          // this section or element was not found
          this.lmsElementNotFound = true;
        } else {
          eventBus.$emit('error', err, null);
        }
        this.root = { type: LmsCourseModelNodeType.Group, id: '', name: '', children: [] };
      }
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDisplayableTime (date: Moment): string {
      return date.format(this.$t('dateFormat.timePattern').toString());
    },
    async addNode (originalNode: LmsCourseModelLeafNode): Promise<LmsCourseModelLeafNodeViewModel> {
      const node = { ...originalNode, grade: '', attemptLink: '' } as LmsCourseModelLeafNodeViewModel;
      if (node.type === LmsCourseModelNodeType.Quiz) {
        // find a result for this quiz
        const quizResult = this.result.quizes.find((r) => r.quizId === node.id);
        if (quizResult !== undefined) {
          // there is one result, get it
          node.grade = (Math.round(quizResult.result * 10) / 10) + ' / 10';
          // now, get corresponding attempt link
          node.attemptLink = await getLmsAttemptLink(quizResult.attemptsIds);
        }
      }
      return node;
    },
    async addLeafs (root: LmsCourseModelNode) {
      if (root.type === LmsCourseModelNodeType.Group) {
        for (const node of root.children) {
          if (node.type === LmsCourseModelNodeType.Group) {
            this.addLeafs(node);
          } else {
            this.promises.push(this.addNode(node));
          }
        }
      } else {
        this.promises.push(this.addNode(root));
      }
    },
    isDone (item: LmsCourseModelLeafNode): boolean {
      switch (item.type) {
        case LmsCourseModelNodeType.Resource:
          return this.result.resources === undefined ? false : this.result.resources.some((r) => r.resourceId === item.id);
        case LmsCourseModelNodeType.Quiz:
          return this.result.quizes === undefined ? false : this.result.quizes.some((r) => r.quizId === item.id);
        default:
          return false;
      }
    }
  },

  watch: {
    element: async function () {
      await this.init();
    }
  }
});

