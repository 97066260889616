















































































import Vue from 'vue';
import { MediationModel, MediationStatus } from '../../_GeneratedClients/SpotClient';
import { VisitsCache, getAllVisits, queryMediations } from '../repository';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import RadioStep from '../components/common/RadioStep.vue';
import { LocaleMessage } from 'vue-i18n';
import { Moment } from 'moment';
import MediationFieldsConfiguration from '../fieldsConfiguration/MediationFieldsConfiguration';
import DisplayValue from '../components/common/DisplayValue.vue';

export default Vue.extend({

  name: 'Mediations',

  components: {
    FilteredListPage,
    RadioStep,
    DisplayValue
  },

  data: () => ({
    visitsCache: {} as VisitsCache,
    fieldsConfiguration: MediationFieldsConfiguration,
    query: queryMediations
  }),

  methods: {
    async onBeforeDataLoad (): Promise<void> {
      this.visitsCache = await getAllVisits();
    },
    setBackground (mediation: MediationModel): string {
      const color = 'bgUnread'; // yellow
      const noColor = 'white';
      if (this.visitsCache[mediation.id] === undefined) {
        return color;
      }
      return mediation.lastUpdate.date.isAfter(this.visitsCache[mediation.id]) ? color : noColor;
    },
    getFirstLine (text: string): string {
      const offset = text.indexOf('\n');
      return offset === -1 ? text : text.substring(0, offset) + ' ...';
    },
    hasStatus (mediation: MediationModel, askedStatus: string[]): boolean {
      return askedStatus.some((s) => s === mediation.status.toString());
    },
    formatDate (date: Moment): string {
      return date.format(this.$t('dateFormat.dateTimePattern').toString());
    },
    formatLargeDate (date: Moment): string {
      return date.format(this.$t('dateFormat.largeDateTimePattern').toString());
    },
    getOriginalStatus (status: MediationStatus): LocaleMessage {
      return this.$t('steps.' + status.toString());
    }
  }
});

