import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';

import Cartographies from '../views/Cartographies.vue';
import Cartography from '../views/Cartography.vue';
import CartographiesPermissions from '../views/CartographiesPermissions.vue';

import Functions from '../views/Functions.vue';
import ViewFunction from '../views/ViewFunction.vue';

import Subjects from '../views/Subjects.vue';
import Subject from '../views/Subject.vue';
import NewSubject from '../views/NewSubject.vue';
import EditSubject from '../views/EditSubject.vue';
import CreateFromSubject from '../views/CreateFromSubject.vue';

import Mediations from '../views/Mediations.vue';
import NewMediation from '../views/NewMediation.vue';
import EditMediation from '../views/EditMediation.vue';
import ViewMediation from '../views/ViewMediation.vue';
import EditMediationReport from '../views/EditMediationReport.vue';

import TrainingModules from '../views/TrainingModules.vue';
import ViewTrainingModule from '../views/ViewTrainingModule.vue';
import NewTrainingModule from '../views/NewTrainingModule.vue';
import EditTrainingModule from '../views/EditTrainingModule.vue';
import CreateFromTrainingModule from '../views/CreateFromTrainingModule.vue';

import TrainingPaths from '../views/TrainingPaths.vue';
import NewTrainingPath from '../views/NewTrainingPath.vue';
import ViewTrainingPath from '../views/ViewTrainingPath.vue';
import EditTrainingPath from '../views/EditTrainingPath.vue';
import TrainingPathReport from '../views/TrainingPathReport.vue';

import MyDashboard from '../views/MyDashboard.vue';
import TrainingPathDashboard from '../views/TrainingPathDashboard.vue';
import GroupTrainingPathDashboard from '../views/GroupTrainingPathDashboard.vue';
import ContributionDashboard from '../views/ContributionDashboard.vue';
import CartographyQualificationDashboard from '../views/CartographyQualificationDashboard.vue';
import GroupQualificationDashboard from '../views/GroupQualificationDashboard.vue';
import UserQualificationDashboard from '../views/UserQualificationDashboard.vue';

import TrainingModuleReferences from '../views/TrainingModuleReferences.vue';
import TrainingPathReferences from '../views/TrainingPathReferences.vue';

import Qualifications from '../views/Qualifications.vue';
import NewQualification from '../views/NewQualification.vue';
import ViewQualification from '../views/ViewQualification.vue';
import EditQualification from '../views/EditQualification.vue';

import Permissions from '../views/Permissions.vue';

import Forbidden from '../views/Forbidden.vue';
import NotFound from '../views/NotFound.vue';

import { loginFromCookie } from '../repository';
import { baseUrl, spotV1Url } from '../Tools';

Vue.use(VueRouter);

// TODO change route from "/subject/:subjectId" to "/subject/view/subjectId" and name "Subject" by ViewSubject ... and so on ...
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboards/mine'
  },
  {
    path: '/cartographies/:filter?',
    name: 'FilteredCartographies',
    component: Cartographies
  },
  {
    path: '/cartography/:cartographyId',
    name: 'Cartography',
    component: Cartography
  },
  {
    path: '/functions/:filter?',
    name: 'FilteredFunctions',
    component: Functions
  },
  {
    path: '/function/:functionId',
    name: 'ViewFunction',
    component: ViewFunction
  },
  {
    path: '/subjects/:filter?',
    name: 'FilteredSubjects',
    component: Subjects
  },
  {
    path: '/subject/add',
    name: 'NewSubject',
    component: NewSubject
  },
  {
    path: '/subject/:subjectId',
    name: 'Subject',
    component: Subject
  },
  {
    path: '/subject/edit/:subjectId',
    name: 'EditSubject',
    component: EditSubject
  },
  {
    path: '/subject/createFrom/:subjectId',
    name: 'CreateFromSubject',
    component: CreateFromSubject
  },
  {
    path: '/mediations/:filter?',
    name: 'FilteredMediations',
    component: Mediations
  },
  {
    path: '/mediation/add',
    name: 'NewMediation',
    component: NewMediation
  },
  {
    path: '/mediation/edit/:mediationId',
    name: 'EditMediation',
    component: EditMediation
  },
  {
    path: '/mediation/view/:mediationId',
    name: 'ViewMediation',
    component: ViewMediation
  },
  {
    path: '/mediation/report/:mediationId',
    name: 'EditMediationReport',
    component: EditMediationReport
  },
  {
    path: '/training/modules/:filter?',
    name: 'FilteredTrainingModules',
    component: TrainingModules
  },
  {
    path: '/training/module/view/:moduleId',
    name: 'ViewTrainingModule',
    component: ViewTrainingModule
  },
  {
    path: '/training/module/add',
    name: 'NewTrainingModule',
    component: NewTrainingModule
  },
  {
    path: '/training/module/edit/:moduleId',
    name: 'EditTrainingModule',
    component: EditTrainingModule
  },
  {
    path: '/training/module/createFrom/:moduleId',
    name: 'CreateFromTrainingModule',
    component: CreateFromTrainingModule
  },
  {
    path: '/training/paths/:filter?',
    name: 'FilteredTrainingPaths',
    component: TrainingPaths
  },
  {
    path: '/training/path/add/:pathId?',
    name: 'NewTrainingPath',
    component: NewTrainingPath
  },
  {
    path: '/training/path/view/:pathId',
    name: 'ViewTrainingPath',
    component: ViewTrainingPath
  },
  {
    path: '/training/path/edit/:pathId',
    name: 'EditTrainingPath',
    component: EditTrainingPath
  },
  {
    path: '/qualifications/:filter?',
    name: 'FilteredQualifications',
    component: Qualifications
  },
  {
    path: '/qualification/add',
    name: 'NewQualification',
    component: NewQualification
  },
  {
    path: '/qualification/view/:qualificationId',
    name: 'ViewQualification',
    component: ViewQualification
  },
  {
    path: '/qualification/edit/:qualificationId',
    name: 'EditQualification',
    component: EditQualification
  },
  {
    path: '/dashboards/mine',
    name: 'MyDashboard',
    component: MyDashboard
  },
  {
    path: '/dashboards/trainingPath',
    name: 'TrainingPathDashboard',
    component: TrainingPathDashboard
  },
  {
    path: '/dashboards/trainingPath/group/:groupId',
    name: 'GroupTrainingPathDashboard',
    component: GroupTrainingPathDashboard
  },
  {
    path: '/dashboards/contributions',
    name: 'ContributionDashboard',
    component: ContributionDashboard
  },
  {
    path: '/dashboards/qualifications/cartography/:cartographyId',
    name: 'CartographyQualificationDashboard',
    component: CartographyQualificationDashboard
  },
  {
    path: '/dashboard/qualifications/group/:groupId',
    name: 'GroupQualificationDashboard',
    component: GroupQualificationDashboard
  },
  {
    path: '/dashboard/qualifications/user/:userId?',
    name: 'UserQualificationDashboard',
    component: UserQualificationDashboard
  },
  {
    path: '/administration/trainingModuleReferences/:filter?',
    name: 'FilteredTrainingModuleReferences',
    component: TrainingModuleReferences
  },
  {
    path: '/administration/trainingPathReferences/:filter?',
    name: 'FilteredTrainingPathReferences',
    component: TrainingPathReferences
  },
  {
    path: '/administration/trainingPathReport',
    name: 'TrainingPathReport',
    component: TrainingPathReport
  },
  {
    path: '/administration/permissions',
    name: 'Permissions',
    component: Permissions
  },
  {
    path: '/administration/cartographies/permissions',
    name: 'CartographiesPermissions',
    component: CartographiesPermissions
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  /* External links */
  { // The LMS is assumed to be at the baseUrl
    path: '/lms',
    beforeEnter () { location.href = baseUrl; }
  },
  {
    path: '/v1',
    name: 'V1Redirection',
    beforeEnter (to: Route) {
      if (typeof (to.query?.to) === 'string') {
        location.href = spotV1Url + '#!/' + to.query.to;
      } else {
        location.href = spotV1Url;
      }
    }
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/spotv2/',
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(route => route.name === 'Login') || await loginFromCookie()) {
    next();
  } else {
    next({ name: 'Login', query: { ...to.query, returnUrl: '/spotv2' + to.path } });
  }
});

export default router;
