




































import Vue from 'vue';
import CartographyFieldsConfiguration from '../fieldsConfiguration/CartographyFieldsConfiguration';
import { queryCartographies } from '../queryEngine/queryCartographies';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import { backendUrl } from '../Tools';
import CartographyCounters from '../components/cartography/CartographyCounters.vue';

export default Vue.extend({

  name: 'Cartographies',

  components: {
    FilteredListPage,
    CartographyCounters
  },

  data: () => ({
    fieldsConfiguration: CartographyFieldsConfiguration,
    query: queryCartographies,
    backendUrl: backendUrl
  })
});

