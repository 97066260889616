















































































































































































































































import Vue, { PropType } from 'vue';
import {
  TrainingModuleItemModel,
  TrainingModuleModel,
  TrainingModuleItemType,
  CreateOrUpdateTrainingModuleRequest,
  IdAndNameModel,
  TrainingModuleType
} from '../../../_GeneratedClients/SpotClient';
import { getEmptyTrainingModule } from '../../repository';
import AddTrainingModuleSpotElements from './AddTrainingModuleSpotElements.vue';
import DisplayTrainingModuleSpotElement from './DisplayTrainingModuleSpotElement.vue';
import AddTrainingModuleMediationElement from './AddTrainingModuleMediationElement.vue';
import DisplayTrainingModuleMediationElement from './DisplayTrainingModuleMediationElement.vue';
import AddTrainingModuleUrlElement from './AddTrainingModuleUrlElement.vue';
import DisplayTrainingModuleUrlElement from './DisplayTrainingModuleUrlElement.vue';
import AddTrainingModuleLmsElements from './AddTrainingModuleLmsElements.vue';
import DisplayTrainingModuleLmsElement from './DisplayTrainingModuleLmsElement.vue';
import ReferencePicker from '../common/ReferencePicker.vue';
import { firstLowerCase } from '../../Tools';

interface TrackingModuleElementMenuItem { label: string; action: () => void; }

export default Vue.extend({

  name: 'AddOrEditTrainingModule',

  components: {
    AddTrainingModuleSpotElements,
    DisplayTrainingModuleSpotElement,
    AddTrainingModuleMediationElement,
    DisplayTrainingModuleMediationElement,
    AddTrainingModuleUrlElement,
    DisplayTrainingModuleUrlElement,
    AddTrainingModuleLmsElements,
    DisplayTrainingModuleLmsElement,
    ReferencePicker
  },

  props: {
    module: { type: Object as PropType<TrainingModuleModel>, required: true },
    isNew: { type: Boolean, default: false },
    title: { type: String, default: '???' },
    canEdit: { type: Boolean, default: false }
  },

  data: () => ({
    moduleToEdit: getEmptyTrainingModule(),
    errorMessages: { name: '', type: '', goals: '', estimatedDuration: '', periodicity: '' },
    globalError: '',
    selectedType: undefined as IdAndNameModel | undefined,
    typeList: [] as IdAndNameModel[],
    duration: '0',
    selectedTimeScale: 'h', // arbitrary, default value = hours
    periodic: false,
    periodicity: '0',
    elementList: [] as TrainingModuleItemModel[],
    showAddTrainingModuleElement: false,
    newElementType: undefined as TrainingModuleItemType | undefined,
    spotLastContext: [] as IdAndNameModel[],
    lmsLastContext: [] as IdAndNameModel[]
  }),

  created () {
    this.typeList = Object.keys(TrainingModuleType).map(key => ({ id: firstLowerCase(key), name: this.$t('moduleType.' + firstLowerCase(key)) } as IdAndNameModel));
    this.initModuleToEdit();
  },

  methods: {
    initModuleToEdit (): void {
      this.moduleToEdit = { ...this.module };
      // New training module's type is always 'other' from now on (not properly defined or used so rolling back to default value)
      // Otherwise keep the type if previously defined
      const found = this.typeList.find(t => t.id === firstLowerCase(this.module.moduleType));
      this.selectedType = found === undefined ? { id: TrainingModuleType.Other, name: this.$t('moduleType.other').toString() } : found;

      if (this.module.estimatedDuration % 60 === 0) {
        // duration is defined in hours
        this.selectedTimeScale = 'h';
        this.duration = Math.round(this.module.estimatedDuration / 60).toString();
      } else {
        // duration is defined in minutes
        this.selectedTimeScale = 'm';
        this.duration = this.module.estimatedDuration.toString();
      }
      if (this.module.periodicity === 0) {
        this.periodic = false;
        this.periodicity = '0';
      } else {
        this.periodic = true;
        this.periodicity = this.module.periodicity.toString();
      }
      for (const item of this.module.moduleItems) {
        this.elementList.push({ ...item });
      }
      this.validateAllEntries();
    },
    validateAllEntries (): void {
      this.nameValidator();
      this.typeValidator();
      this.goalsValidator();
      this.estimatedDurationValidator();
      this.periodicityValidator();
    },
    getBgColor (error: string, force = false): string {
      if (!this.canEdit && !force) {
        return 'bgDisabled';
      }
      return error === '' ? 'bgAccent' : 'bgError';
    },
    getPeriodicityBgColor (): string {
      if (this.periodic) {
        return this.getBgColor(this.errorMessages.periodicity);
      }
      return 'bgDisabled';
    },
    onSaveClicked (): void {
      if (Object.values(this.errorMessages).some((v) => v !== '') || this.elementList.length === 0) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      // prepare to save
      const toSave: CreateOrUpdateTrainingModuleRequest = {
        name: this.moduleToEdit.name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        moduleType: this.selectedType!.id as TrainingModuleType,
        goals: this.moduleToEdit.goals,
        estimatedDuration: this.selectedTimeScale === 'h' ? parseInt(this.duration, 10) * 60 : parseInt(this.duration, 10),
        reference: this.moduleToEdit.reference?.code ?? undefined,
        periodicity: this.periodic ? parseInt(this.periodicity) : 0,
        passThreshold: this.moduleToEdit.passThreshold,
        moduleItems: [...this.elementList]
      };
      this.$emit('save', toSave);
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    nameValidator (): void {
      this.hideGlobalError();
      this.errorMessages.name = '';
      if (this.moduleToEdit.name !== this.moduleToEdit.name.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.name = this.$t('beginEndWhitespaceError').toString();
      } else if (this.moduleToEdit.name.length === 0) {
        // name must be set
        this.errorMessages.name = this.$t('emptyFieldError').toString();
      }
    },
    typeValidator (): void {
      this.hideGlobalError();
      this.errorMessages.type = '';
      if (this.selectedType === undefined) {
        // type must be set
        this.errorMessages.type = this.$t('emptyFieldError').toString();
      }
    },
    goalsValidator (): void {
      this.hideGlobalError();
      this.errorMessages.goals = '';
      if (this.moduleToEdit.goals.trim().length === 0) {
        // name must be set
        this.errorMessages.goals = this.$t('emptyFieldError').toString();
      }
    },
    estimatedDurationValidator (): void {
      this.hideGlobalError();
      this.errorMessages.estimatedDuration = '';
      if (this.duration.match(/^[1-9][0-9]*$/) === null) {
        this.errorMessages.estimatedDuration = this.$t('nonZeroNumericValueExpected').toString();
      }
    },
    periodicityValidator (): void {
      this.hideGlobalError();
      this.errorMessages.periodicity = '';
      if (!this.periodic) {
        return;
      }
      if (this.periodicity.match(/^[1-9][0-9]*$/) === null) {
        this.errorMessages.periodicity = this.$t('nonZeroNumericValueExpected').toString();
      }
    },
    newSpotItem () {
      this.newElementType = TrainingModuleItemType.Spot;
      this.showAddTrainingModuleElement = true;
    },
    newMediationItem () {
      this.newElementType = TrainingModuleItemType.Mediation;
      this.showAddTrainingModuleElement = true;
    },
    newUrlItem () {
      this.newElementType = TrainingModuleItemType.Url;
      this.showAddTrainingModuleElement = true;
    },
    newLmsItem () {
      this.newElementType = TrainingModuleItemType.Lms;
      this.showAddTrainingModuleElement = true;
    },
    insertNewElements (newElements: TrainingModuleItemModel[]): void {
      this.showAddTrainingModuleElement = false;
      for (const element of newElements) {
        this.elementList.push({ ...element });
      }
      this.newElementType = undefined;
    },
    elementUp (idx: number): void {
      const buffer = this.elementList[idx];
      this.elementList.splice(idx, 1);
      this.elementList.splice(idx - 1, 0, buffer);
    },
    elementDown (idx: number): void {
      const buffer = this.elementList[idx];
      this.elementList.splice(idx, 1);
      this.elementList.splice(idx + 1, 0, buffer);
    },
    deleteElement (idx: number): void {
      this.elementList.splice(idx, 1);
    },
    setSpotLastContext (context: IdAndNameModel[]): void {
      this.spotLastContext = context;
    },
    setLmsLastContext (context: IdAndNameModel[]): void {
      this.lmsLastContext = context;
    }
  },

  computed: {
    timeScale () {
      return [
        { label: this.$t('hours').toString(), value: 'h' },
        { label: this.$t('minutes').toString(), value: 'm' }
      ];
    },
    listItems (): TrackingModuleElementMenuItem[] {
      return [
        { label: this.$t('elementType.spot').toString(), action: this.newSpotItem },
        { label: this.$t('elementType.mediation').toString(), action: this.newMediationItem },
        { label: this.$t('elementType.url').toString(), action: this.newUrlItem },
        { label: this.$t('elementType.lms').toString(), action: this.newLmsItem }
      ];
    }
  },

  watch: {
    module: function () {
      this.initModuleToEdit();
    }
  }
});

