


































































































import Vue from 'vue';
import {
  ContextViewModel,
  getContextViewModel,
  VisitsCache,
  getAllVisits,
  getDiagramV1Link,
  getMindmapV1Link
} from '../repository';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import DisplayContext from '../components/common/DisplayContext.vue';
import DisplayMindMap from '../components/subjects/DisplayMindMap.vue';
import DisplayDiagram from '../components/subjects/DisplayDiagram.vue';
import SubjectStep from '../components/subjects/SubjectStep.vue';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { Moment } from 'moment';
import { LocaleMessage } from 'vue-i18n';
import { getFriendlyDate } from '../Tools';
import { ContextModel, MindMapNode, SubjectModel } from '../../_GeneratedClients/SpotClient';
import SubjectFieldsConfiguration from '../fieldsConfiguration/SubjectFieldsConfiguration';
import { querySubjects } from '../queryEngine/querySubjects';
import { Location } from 'vue-router';
import DisplayStandardizedMindMap from '../components/subjects/DisplayStandardizedMindMap.vue';

export default Vue.extend({

  name: 'Subjects',

  components: {
    DisplayContext,
    DisplayDiagram,
    DisplayLifetimeEvent,
    DisplayMindMap,
    DisplayStandardizedMindMap,
    DisplayValue,
    FilteredListPage,
    SubjectStep
  },

  data: () => ({
    visitsCache: {} as VisitsCache,
    fieldsConfiguration: SubjectFieldsConfiguration,
    query: querySubjects
  }),

  methods: {
    async onBeforeDataLoad () {
      this.visitsCache = await getAllVisits();
    },
    setBackground (subject: SubjectModel): string {
      const color = 'bgUnread'; // yellow
      const noColor = 'white';
      if (this.visitsCache[subject.id] === undefined) {
        return color;
      }
      if (subject.lastMindmapUpdate === undefined) {
        return noColor;
      }
      return subject.lastMindmapUpdate.isAfter(this.visitsCache[subject.id]) ? color : noColor;
    },
    getDate (date: Moment | undefined): string | LocaleMessage {
      if (date) {
        return getFriendlyDate(date, 'long');
      }
      return this.$t('never');
    },
    getMainNodeStatus (nodes: MindMapNode[]): string {
      const firstNode = nodes.find((n) => n.code === '0');
      return firstNode?.status ?? '';
    },
    getContextViewModel (contexts: ContextModel[]): ContextViewModel[] {
      return getContextViewModel(contexts);
    },
    routeToMindMapV1 (subject: SubjectModel, standardized = false): Location | null {
      if (subject.contexts.length === 0) {
        return null; // Not yet loaded (would never occur)
      }
      return { name: 'V1Redirection', query: { to: getMindmapV1Link(subject, standardized) } };
    },
    routeToDiagramV1 (subject: SubjectModel): Location | null {
      if (subject.contexts.length === 0) {
        return null; // Not yet loaded (would never occur)
      }
      return { name: 'V1Redirection', query: { to: getDiagramV1Link(subject) } };
    }
  }
});
