




































import DisplayContext from '../components/common/DisplayContext.vue';
import Vue from 'vue';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import FunctionFieldsConfiguration from '../fieldsConfiguration/FunctionFieldsConfiguration';
import { queryFunctions } from '../queryEngine/queryFunctions';
import { backendUrl } from '../Tools';
import DisplayFunctionCounters from '../components/function/DisplayFunctionCounters.vue';

export default Vue.extend({

  name: 'Functions',

  components: {
    FilteredListPage,
    DisplayContext,
    DisplayFunctionCounters
  },

  data: () => ({
    fieldsConfiguration: FunctionFieldsConfiguration,
    query: queryFunctions,
    backendUrl: backendUrl
  })
});

