







































































import Vue, { PropType } from 'vue';
import {
  ActivityModel,
  CartographyModel,
  ContextItemType,
  SpotTrainingModuleItemModel,
  SpotTrainingModuleItemResult,
  SubjectModel
} from '../../../_GeneratedClients/SpotClient';
import { Moment } from 'moment';
import {
  ContextNodeViewModel,
  ContextViewModel,
  getContextViewModel,
  getMindmapV1Link,
  getSpotItemById,
  getSubjectsFromFunction,
  ISpotItem
} from '../../repository';
import { Location } from 'vue-router';
import DisplayModuleSpotSource from '../common/DisplayModuleSpotSource.vue';

export default Vue.extend({

  name: 'DisplayAssignedModuleSpotElement',

  components: {
    DisplayModuleSpotSource
  },

  props: {
    /** the module SPOT element to be displayed */
    element: { type: Object as PropType<SpotTrainingModuleItemModel>, required: true },
    /** the actual result of the element */
    result: { type: Object as PropType<SpotTrainingModuleItemResult>, required: true },
    /** true if parent module is disabled */
    disabled: { type: Boolean, default: false },
    /** true if action button click must have a result */
    isActive: { type: Boolean, default: false }
  },

  data: () => ({
    selectedSpotItem: undefined as ISpotItem | undefined,
    /** the subject(s) attached to or under the given element */
    subjectList: [] as SubjectModel[],
    /** context of the given element */
    elementContexts: [] as ContextViewModel[],
    /** true during initial details loading */
    loading: true,
    /** true if element is found (i.e. user has permission to see it) */
    found: false
  }),

  async mounted () {
    const id = this.element.spotItemId;
    this.selectedSpotItem = await getSpotItemById(id);
    this.loading = false;
    if (this.selectedSpotItem) {
      this.found = true;
      switch (this.selectedSpotItem.item.type) {
        case ContextItemType.Cartography:
          for (const activity of (this.selectedSpotItem.item as CartographyModel).activities) {
            this.getSubjectsFromActivity(activity);
          }
          this.elementContexts = [{ nodes: [{ name: this.selectedSpotItem.contexts[0].hierarchy[0].name, link: undefined }] }];
          break;
        case ContextItemType.Activity: {
          this.getSubjectsFromActivity(this.selectedSpotItem.item as ActivityModel);
          const nodes = [] as ContextNodeViewModel[];
          for (const node of this.selectedSpotItem.contexts[0].hierarchy) {
            nodes.push({ name: node.name, link: undefined });
          }
          nodes.push({ name: this.selectedSpotItem.item.name, link: undefined });
          this.elementContexts = [{ nodes: nodes }];
          break;
        }
        case ContextItemType.Function:
          this.subjectList = this.subjectList.concat(await getSubjectsFromFunction(id));
          this.elementContexts = [
            {
              nodes: [
                { name: this.selectedSpotItem.contexts[0].hierarchy[0].name, link: undefined },
                { name: this.selectedSpotItem.contexts[0].hierarchy[1].name, link: undefined },
                { name: this.selectedSpotItem.item.name, link: undefined }
              ]
            }
          ];
          break;
        case ContextItemType.Subject:
          this.subjectList.push(this.selectedSpotItem.item as SubjectModel);
          this.elementContexts = getContextViewModel(this.selectedSpotItem.contexts);
          break;
      }
    }
  },

  methods: {
    getSubjectsFromActivity (activity: ActivityModel): void {
      this.subjectList = this.subjectList.concat(activity.subjects);
      for (const act of activity.activities) {
        this.getSubjectsFromActivity(act);
      }
    },
    isDone (subject: SubjectModel): boolean {
      return this.result.subjects === undefined ? false : this.result.subjects.some((r) => r.subjectId === subject.id);
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDisplayableTime (date: Moment): string {
      return date.format(this.$t('dateFormat.timePattern').toString());
    },
    routeToMindMapV1 (subject: SubjectModel): Location | null {
      if (subject.contexts.length === 0) {
        return null; // Not yet loaded (would never occur)
      }
      return { name: 'V1Redirection', query: { to: getMindmapV1Link(subject) } };
      // the following line will have to replace the previous one if the standardized mindmaps are to be given priority (see the relevant part of the template)
      // return { name: 'V1Redirection', query: { to: getMindmapV1Link(subject, subject.lifetimeEvents.standardized !== undefined) } };
    }
  }
});

