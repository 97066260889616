import moment from 'moment';
import { client } from './Client';
import { sortArrayByString, sortArrayByMoment } from '../Tools';
import { ISpotItem, SearchResponse, SearchResponseCategory, SpotEntity, SpotItem } from './Types';
import {
  CartographyModel,
  FunctionModel,
  MindMapNode,
  SubjectLifetimeEventModel,
  SubjectModel,
  IdAndNameModel,
  SiteModel,
  ContextHierarchyNode,
  ContextItemType,
  ActivityModel,
  ContextModel
} from '../../_GeneratedClients/SpotClient';
import { getConnectedUser, FilterableSubjectModel } from '../repository';

const siteList: SpotItem<SiteModel>[] = [];
const siteIndex: IdAndNameModel[] = [];
const cartographyList: SpotItem<CartographyModel>[] = [];
const cartographySiteIndex: IdAndNameModel[] = [];
const functionList: SpotItem<FunctionModel>[] = [];
const activityList: SpotItem<ActivityModel>[] = [];
const subjectList: SpotItem<FilterableSubjectModel>[] = [];
const nodeList: SpotItem<MindMapNode>[] = [];
let functionSubjectIndex: { [key: string]: SubjectModel[] } = {};
let spotIndex: { [key: string]: ISpotItem } = {};
let preloadListPromise: Promise<void> | null = null;

// *** preload and generic functions **********************************************************

/**
 * internal function to extarct subjects (and add them to subjectList) from acivity list of a cartography
 *
 * @param activities the activities list from where to extract subjects
 * @param parentContexts the parent context(s)
 */
function getSubjectsFromActivities (activities: ActivityModel[], parentContexts: ContextHierarchyNode[]): void {
  for (const activity of activities) {
    // add activity to activity list
    const spotActivity = { contexts: [{ hierarchy: parentContexts }], item: activity };
    activityList.push(spotActivity);
    spotIndex[activity.id] = spotActivity;
    for (const subject of activity.subjects) {
      const ancestors: Set<string> = new Set(subject.contexts.flatMap((context) => context.hierarchy.flatMap((node) => node.id)));
      subjectDatesToMoment(subject);
      const spotSubject = { contexts: subject.contexts, item: { ...subject, ancestors: ancestors } };
      subjectList.push(spotSubject);
      spotIndex[subject.id] = spotSubject;
    }
    const nextContext = parentContexts.concat([{ id: activity.id, code: activity.code, name: activity.name, type: ContextItemType.Activity }]);
    getSubjectsFromActivities(activity.activities, nextContext);
  }
}

/**
 * create SpotItem list for each of four categories (cartography, function, subject and mindMap node)
 *
 * @returns The promise that resolves when the cartographies have been loaded.
 */
async function preloadDataFromApi (): Promise<void> {
  // get data from api
  const sites = await client.getAllCartographies();
  // creates site list
  for (const site of sites) {
    const spotSite = { contexts: [], item: site }; // no context for the site level
    siteList.push(spotSite);
    spotIndex[site.id] = spotSite;
    // prepare context for cartography level
    const cartoContext = [{ id: site.id, code: site.code, name: site.name, type: ContextItemType.Site }] as ContextHierarchyNode[];
    // add cartographies to cartography list
    for (const carto of site.cartographies) {
      const spotCartography = { contexts: [{ hierarchy: cartoContext }], item: carto }; // only one context for cartography level
      cartographyList.push(spotCartography);
      spotIndex[carto.id] = spotCartography;
      cartographySiteIndex.push({ id: carto.id, name: carto.name + ' (' + site.name + ')' });
      // prepare context for function level
      const functionContext = cartoContext.concat([{ id: carto.id, code: carto.code, name: carto.name, type: ContextItemType.Cartography }]);
      // add functions to function list
      for (const func of carto.functions) {
        const spotFunction = { contexts: [{ hierarchy: functionContext }], item: func }; // only one context for function level
        functionList.push(spotFunction);
        spotIndex[func.id] = spotFunction;
      }
      // add subjects to subject list
      getSubjectsFromActivities(carto.activities, functionContext); // functionContext is same as first level activity context
    }
  }
  // add mindmap nodes to node list
  for (const subject of subjectList) {
    // prepare context
    const nodeContexts = [] as ContextModel[];
    for (const context of subject.contexts) {
      nodeContexts.push({
        hierarchy: context.hierarchy.concat([{ id: subject.item.id, code: subject.item.code, name: subject.item.name, type: ContextItemType.Subject }])
      });
      if (context.hierarchy.length > 2 && context.hierarchy[2].type === ContextItemType.Function) {
        // add function - subject relation to index
        const functionId = context.hierarchy[2].id;
        if (functionSubjectIndex[functionId] === undefined) {
          // key doesn't yet exist -> create it with first item
          functionSubjectIndex[functionId] = [subject.item];
        } else {
          // key already exists -> just add subject in list
          functionSubjectIndex[functionId].push(subject.item);
        }
      }
    }
    for (const node of subject.item.mindMapItems) {
      const spotNode = { contexts: nodeContexts, item: node };
      nodeList.push(spotNode);
      spotIndex[node.id] = spotNode;
    }
  }
  for (const site of siteList) {
    siteIndex.push({ id: site.item.id, name: site.item.name });
  }
  sortArrayByString(cartographySiteIndex, (csi) => csi.name); // on trie par ordre alpha des noms (cartographie, puis site)
}

/**
 * Preloads the data for the search bar and subjects hierarchy. Ugly, but the DB doesn't give us the choice.
 *
 * @returns The promise that resolves when it has been saved.
 */
export async function dataPreload (): Promise<void> {
  if (preloadListPromise === null) {
    preloadListPromise = preloadDataFromApi();
  }
  try {
    await preloadListPromise;
  } catch (err) {
    // In case of error, don't cache the result
    dataPreloadUnload();
    throw err;
  }
  // sort subjectList with last updated subject first
  sortArrayByMoment(subjectList, (item) => item.item.lastMindmapUpdate, 'desc'); // TODO : move that out of the dataPreload() function, which is called very often
}

/**
 * Unloads all data that were preloaded with dataPreload
 */
export function dataPreloadUnload (): void {
  preloadListPromise = null;
  siteList.splice(0, siteList.length);
  siteIndex.splice(0, siteIndex.length);
  cartographyList.splice(0, cartographyList.length);
  cartographySiteIndex.splice(0, cartographySiteIndex.length);
  activityList.splice(0, activityList.length);
  functionList.splice(0, functionList.length);
  subjectList.splice(0, subjectList.length);
  nodeList.splice(0, nodeList.length);
  functionSubjectIndex = {};
  spotIndex = {};
}

/**
 * Gets a SPOT item from memory storage, given his id.
 *
 * @param spotItemId the identifier of object to return
 * @returns a ISpotItem, if found, otherwise "undefined"
 */
export async function getSpotItemById (spotItemId: string): Promise<ISpotItem | undefined> {
  await dataPreload();
  return spotIndex[spotItemId];
}

/**
 * Gets the map of spot objects given their id
 *
 * @param ids The searched spot object identifiers
 * @returns The mapping that associates the identifiers to the names
 */
export async function resolveSpotItemNames (ids: string[]): Promise<Record<string, string>> {
  await dataPreload();
  const results: Record<string, string> = {};
  for (const id of ids) {
    const item = spotIndex[id];
    if (item !== undefined) {
      results[item.item.id] = item.item.name;
    }
  }
  return results;
}

// *** site functions *************************************************************************

/**
 * gets the list of Spot sites
 *
 * @returns the list of Spot sites (as spot entity list)
 * */
export async function getSiteList (): Promise<SpotItem<SiteModel>[]> {
  await dataPreload();
  return siteList;
}

/**
 * Gets the list of sites that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteSite (search: string): Promise<IdAndNameModel[]> {
  if (search.length === 0) {
    return Promise.resolve(siteIndex);
  }
  return Promise.resolve(siteIndex.filter(site => site.name.includes(search)));
}

// *** cartography functions ******************************************************************

/**
 * This function is used to make the filters on the cartographies.
 * Externalizing the filter in queryCartographies.ts avoids circular dependencies between this file and CartographyFieldsConfiguration.
 * This will be removed when the cartographies query API will be done
 *
 * @returns All the cartographies
 */
export async function getAllCartographies (): Promise<SpotItem<CartographyModel>[]> {
  await dataPreload();
  return cartographyList;
}

/**
 * gets a formatted cartography list as IdAndNameModel[]
 *
 * @returns the entire cartography list, with combined "cartography (site)" as name and cartography identifier as id
 */
export async function getCartographySiteIndex (): Promise<IdAndNameModel[]> {
  await dataPreload();
  return cartographySiteIndex;
}

/**
 * Gets the list of "cartography (site)" that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteCartographySite (search: string): Promise<IdAndNameModel[]> {
  if (search.length === 0) {
    return Promise.resolve(cartographySiteIndex);
  }
  return Promise.resolve(cartographySiteIndex.filter(sci => sci.name.toUpperCase().includes(search.toUpperCase())));
}

/**
 * Gets the list of cartography that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteCartography (search: string): Promise<IdAndNameModel[]> {
  const list = search.length === 0 ? cartographyList : cartographyList.filter(c => c.item.name.toUpperCase().includes(search.toUpperCase()));
  return Promise.resolve(list.map(c => ({ id: c.item.id, name: c.item.name })));
}

// *** function functions *********************************************************************

/**
 * Gets the list of functions attached to a cartography
 *
 * @param cartographyId the identifier of source cartography
 * @returns a list of functions (can be empty)
 */
export async function getFunctionsFromCartography (cartographyId: string): Promise<FunctionModel[]> {
  await dataPreload();
  const foundCartography = cartographyList.find(c => c.item.id === cartographyId);
  if (foundCartography !== undefined) {
    return Promise.resolve(foundCartography.item.functions);
  } else {
    return Promise.resolve([]);
  }
}

/**
 * This function is used to make the filters on the functions.
 * Externalizing the filter in queryFunctions.ts avoids circular dependencies between this file and FunctionFieldsConfiguration.
 * This will be removed when the function query API will be done
 *
 * @returns All the functions
 */
export async function getAllFunctions (): Promise<SpotItem<FunctionModel>[]> {
  await dataPreload();
  return functionList;
}

// *** activity functions *********************************************************************

/**
 * Gets the list of activities directly attached to a cartography
 *
 * @param cartographyId the identifier of source cartography
 * @returns a list of activities(can be empty)
 */
export async function getActivitiesFromCartography (cartographyId: string): Promise<ActivityModel[]> {
  await dataPreload();
  const foundCartography = cartographyList.find(c => c.item.id === cartographyId);
  if (foundCartography !== undefined) {
    return Promise.resolve(foundCartography.item.activities);
  } else {
    return Promise.resolve([]);
  }
}

/**
 * Gets the list of sub-activities attached to an activity from a cartography
 *
 * @param activityId the identifier of source activity
 * @param cartographyId the identifier of source cartography
 * @returns a list of sub-activities (can be empty)
 */
export async function getSubActivitiesFromActivityAndCartography (activityId: string, cartographyId: string): Promise<ActivityModel[]> {
  await dataPreload();
  const foundCartography = cartographyList.find(c => c.item.id === cartographyId);
  if (foundCartography !== undefined) {
    const foundActivity = foundCartography.item.activities.find(a => a.id === activityId);
    if (foundActivity !== undefined) {
      return Promise.resolve(foundActivity.activities);
    } else {
      return Promise.resolve([]);
    }
  } else {
    return Promise.resolve([]);
  }
}

// *** subject functions **********************************************************************

/**
 * Gets the number of subjects currently loaded
 *
 * @returns The total number of subjects
 */
export async function getSubjectsCount (): Promise<number> {
  await dataPreload();
  return subjectList.length;
}

/**
 * Private function used by getSubjectsCountFromActivity - gets the number of subjects attached under an activity branch
 *
 * @param activity the activity from which start counting
 * @returns the number of subjects counted
 */
function countActivitySubjects (activity: ActivityModel): number {
  let count = activity.subjects.length;
  for (const act of activity.activities) {
    count += countActivitySubjects(act);
  }
  return count;
}

/**
 * Gets the number of attached subjects to activities of a cartography or activity item
 *
 * @param itemId the identifier of the cartography or activity from which attached subjects are counted
 * @returns the number of attached subjects
 */
export async function getSubjectsCountFromActivity (itemId: string): Promise<number> {
  await dataPreload();
  const spotItem = await getSpotItemById(itemId);
  if (spotItem) {
    let counter = 0;
    switch (spotItem?.item.type) {
      case ContextItemType.Cartography:
        for (const activity of (spotItem.item as CartographyModel).activities) {
          counter += countActivitySubjects(activity);
        }
        break;
      case ContextItemType.Activity:
        counter += countActivitySubjects(spotItem.item as ActivityModel);
        break;
    }
    return counter;
  }
  return 0;
}

/**
 * Gets the number of attached subjects to a function
 *
 * @param functionId the identifier of the function from which attached subjects are counted
 * @returns the number of attached subjects
 */
export async function getSubjectsCountFromFunction (functionId : string): Promise<number> {
  if (functionSubjectIndex[functionId] === undefined) {
    return 0;
  }
  return functionSubjectIndex[functionId].length;
}

/**
 * Gets the list of the subjects attached to a function
 *
 * @param functionId the identifier of the parent function where find searched subjects
 * @returns the list of subjects attached to the refered function
 */
export async function getSubjectsFromFunction (functionId: string): Promise<SubjectModel[]> {
  await dataPreload();
  return functionSubjectIndex[functionId] === undefined ? [] : functionSubjectIndex[functionId];
}

/**
 * change dates in subjectModel from iso format to moment format
 *
 * @param subject the subject within which the dates are changed
 */
function subjectDatesToMoment (subject: SubjectModel): void {
  subject.lastMindmapUpdate = (subject.lastMindmapUpdate) ? moment(subject.lastMindmapUpdate) : undefined;
  for (const eventType of Object.keys(subject.lifetimeEvents) as (keyof SubjectLifetimeEventModel)[]) {
    const evt = subject.lifetimeEvents[eventType];
    if (evt) {
      subject.lifetimeEvents[eventType] = { ...evt, date: moment(evt.date) };
    }
  }
}

/**
 * Gets a subject from API, given its identifier
 *
 * @param subjectId The subject identifer
 * @returns The subject
 */
export async function getSubjectById (subjectId: string): Promise<SubjectModel> {
  const subject = await client.getSubjectById(subjectId);
  subjectDatesToMoment(subject);
  const subjectItem = subjectList.find((item) => item.item.id === subjectId);
  if (subjectItem) {
    // Update subject (local storage) with the newest values
    subjectItem.item.lifetimeEvents = subject.lifetimeEvents;
    subjectItem.item.ratings = subject.ratings;
  }
  return subject;
}

/**
 * This function is used to make the filters on the subjects.
 * Externalizing the filter in querySubjects.ts avoids circular dependencies between this file and SubjectFieldsConfiguration.
 * This will be removed when the subjects query API is done
 *
 * @returns All the subjects
 */
export async function getAllSubjects (): Promise<FilterableSubjectModel[]> {
  await dataPreload();
  return subjectList.map(s => s.item);
}

/**
 * Gets a page of subject details
 *
 * @param pageNum The page number (starts at 1)
 * @param pageLength The length of each page
 * @returns The list of subjects to show on the page
 */
export async function getSubjectDetailPage (pageNum = 1, pageLength = 15): Promise<SubjectModel[]> {
  await dataPreload();
  const first = (pageNum - 1) * pageLength;
  const last = Math.min(first + pageLength, subjectList.length);
  return subjectList.slice(first, last).map(i => i.item);
}

/**
 * reload subject from API, and updates local storage
 *
 * @param subjectId The subject identiifer
 */
export async function reloadSubject (subjectId: string): Promise<void> {
  const subject = subjectList.find((s) => s.item.id === subjectId);
  if (subject) {
    const updatedSubject = await getSubjectById(subjectId);
    subject.item = { ...updatedSubject, ancestors: subject.item.ancestors };
  }
}

/**
 * Gets the map of subjects given their id
 *
 * @param ids The searched subjects identifiers
 * @returns The mapping that associates a subject identifier to a subject name
 */
export async function resolveSubjectNames (ids: string[]): Promise<Record<string, string>> {
  await dataPreload();
  const results: Record<string, string> = {};
  if (ids.length > 0) {
    for (const subject of subjectList) {
      if (ids.includes(subject.item.id)) {
        results[subject.item.id] = subject.item.name;
      }
    }
  }
  return results;
}

// *** mindmap node functions *****************************************************************

/**
 * Gets e a list of the mindmap nodes attached to a subject
 *
 * @param subjectId the identifier of the parent subject where find searched mindmap nodes
 * @returns the list of mindmap nodes attached to the refered subject
 */
export async function getNodesFromSubject (subjectId: string): Promise<MindMapNode[]> {
  const subject = subjectList.find((s) => s.item.id === subjectId);
  return subject ? subject.item.mindMapItems : [];
}

// *** user functions *************************************************************************

/**
 * Gets the list of users that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteUser (search: string): Promise<IdAndNameModel[]> {
  if (search.length === 0) {
    const connectedUser = getConnectedUser();
    if (connectedUser) {
      return Promise.resolve([{ id: connectedUser.id, name: connectedUser.name }]);
    } else {
      return Promise.reject(new Error('User not connected'));
    }
  }
  return client.searchUser(search);
}

/**
 * Gets the list of users that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteUserWithoutDefault (search: string): Promise<IdAndNameModel[]> {
  return client.searchUser(search);
}

/**
 * Gets the map of users given their id
 *
 * @param ids The searched user identifiers
 * @returns The mapping that associates a user identifier to a user name
 */
export function resolveUserNames (ids: string[]): Promise<Record<string, string>> {
  return client.getUserNames(ids);
}

/**
 * gets user identifier list from group list
 *
 * @param groupsId identifiers of groups containing the wanted users
 * @returns the list of user identifiers expected
 */
export async function getUsersIdByGroups (groupsId: string[]): Promise<string[]> {
  const result = await client.getUserByGroups(groupsId);
  return result.map((item) => item.id);
}

/**
 * gets the list of membership groups from a list of user identfiers
 *
 * @param usersId the list of user identifiers for which membership groups are collected
 * @returns for each user, the list of membership groups
 */
export async function getUsersGroups (usersId: string[]): Promise<{ [key: string]: IdAndNameModel[]; }> {
  return await client.getUsersGroups(usersId);
}

/**
 * Gets the list of group that contains the search in their name
 *
 * @param search The search
 * @returns The search results
 */
export function autocompleteGroup (search: string): Promise<IdAndNameModel[]> {
  return client.searchGroup(search);
}

/**
 * gets the list of all user identifiers
 *
 * @returns the list of all user identifiers
 */
export async function getAllUserIds (): Promise<string[]> {
  const groupIds = (await client.searchGroup('')).map(g => g.id); // when searchGroup parameter is empty, function returns all groups.
  return await getUsersIdByGroups(groupIds);
}

// *** Training module functions **************************************************************

/**
 * Gets the list of training modules that contains the search in their name
 *
 * @param search the search
 * @returns The search results
 */
export function autocompleteTrainingModule (search: string): Promise<IdAndNameModel[]> {
  return client.searchTrainingModule(search);
}

/**
 * Gets the map of training modules given their id
 *
 * @param ids The searched training module identifiers
 * @returns The mapping that associates a training module identifier to his name
 */
export function resolveTrainingModuleNames (ids: string[]): Promise<Record<string, string>> {
  return client.getTrainingModuleNames(ids);
}

// *** other functions ************************************************************************

/**
 * Gets the type of a spot object, from its identifier
 *
 * @param id the identifier of spot object to find
 * @returns the type of specified spot object (cartography, function, ...)
 */
export async function getSpotTypeFromId (id: string): Promise<ContextItemType | undefined> {
  await dataPreload();
  return spotIndex[id].item.type;
}

/**
 * The internal function, called by itemSearch that performs the search on the given list.
 *
 * @param searched The text that is searched
 * @param items The list against which the search is performed.
 * @param maxItems The maximum number of items to retrieve
 * @returns The search results
 */
function itemSearchInternal<TItem extends SpotEntity> (searched: string, items: SpotItem<TItem>[], maxItems: number): SearchResponseCategory<TItem> {
  let matchList: SpotItem<TItem>[] = [];
  searched = searched.toUpperCase();
  for (const item of items) {
    if (item.item.name.toUpperCase().includes(searched)) {
      matchList.push(item); // on récupère tous les noms qui contiennent au moins une occurence
    }
  }
  const totalFound = matchList.length;
  sortArrayByString(matchList, (i) => i.item.name); // on trie par ordre alpha des noms
  if (maxItems > 0) {
    matchList = matchList.slice(0, maxItems);
  }
  return { total: totalFound, items: matchList }; // on retourne un max de "maxSearchLength" réponses
}

/**
 * Performs a research
 *
 * @param searched The text that is searched
 * @param maxItemsPerCategory The maximum number of items per category to retrieve
 * @returns The search results
 */
export async function itemSearch (searched: string, maxItemsPerCategory: number): Promise<SearchResponse> {
  await dataPreload();

  return {
    cartography: itemSearchInternal(searched, cartographyList, maxItemsPerCategory),
    function: itemSearchInternal(searched, functionList, maxItemsPerCategory),
    subject: itemSearchInternal(searched, subjectList, maxItemsPerCategory),
    node: itemSearchInternal(searched, nodeList, maxItemsPerCategory)
  };
}
